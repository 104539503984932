<template>
  <div id="member_lose" class="container--fluid pt-3">
    <v-card>
      <v-card-title>
        ข้อมูลสมาชิกพ้นสภาพ
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field
        ><v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.getters.getlistMemberLose"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> จัดการข้อมูล
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="item.sm_type_eject!=3" link @click="AuthenEdit(item, 'ReturnState')">
                <v-list-item-title
                  ><v-icon>mdi-undo-variant</v-icon> คืนสภาพสมาชิก</v-list-item-title
                >
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-title
                  ><v-icon>mdi-alert-circle</v-icon> กรณีเสียชีวิตจะไม่สามารถคืนสถานะได้</v-list-item-title
                >
              </v-list-item>
              <!-- <div v-if="item.sm_type_eject == '3'">
                <v-divider></v-divider>
                <v-list-item link @click="AuthenEdit(item, 'ReturnState')">
                  <v-list-item-title
                    ><v-icon>mdi-adobe-acrobat</v-icon>แบบขอรับเงินสงเคราะห์
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="AuthenEdit(item, 'ReturnState')">
                  <v-list-item-title
                    ><v-icon>mdi-adobe-acrobat</v-icon>แบบรับเงินสงเคราะห์
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="AuthenEdit(item, 'ReturnState')">
                  <v-list-item-title
                    ><v-icon>mdi-adobe-acrobat</v-icon>แบบจ่ายเงินสงเคราะห์
                  </v-list-item-title>
                </v-list-item>
              </div> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <AuthenEditData
      :dialogAuthenEdit="dialogAuthenEdit"
      :dataChild="dataChild"
      :AcType="AcType"
      @ResultAuthen="choiseAc"
    ></AuthenEditData>
  </div>
</template>

<script>
import AuthenEditData from "@/components/authenEditData.vue";
import XLSX from "xlsx";
export default {
  components: {
    AuthenEditData,
  },
  name: "member_lose",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "รหัสสมาชิก",
          align: "start",
          value: "mem_id",
        },
        { text: "ชื่อ-นามสกุล", value: "mem_fullname" },
        { text: "เลขบัตรประชาชน", value: "mem_people_id" },
        { text: "ตำบล,หมู่บ้าน", value: "LocName2" },
        { text: "วันที่เป็นสมาชิก", value: "mem_regis_date_th" },
        { text: "วันที่พ้นสภาพ", value: "sm_date_eject_th" },
        { text: "ประเภทพ้นสภาพ", value: "sm_type_name" },
        { text: "จัดการ", value: "actions" },
      ],
      statAC: "AddNew",
      dataChild: {},
      dialog: false,
      dialogAuthenEdit: false,
      AcType: "",
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.$store.getters.getlistMemberLose);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "member_reject.xlsx");
    },
    setClear() {
      this.dialog = false;
      this.dialogfrm_member_lose = false;
      this.statAC = "";
      this.dataChild = {};
    },
    AuthenEdit(item, type) {
      this.setClear();
      this.AcType = type;
      this.dataChild = item;
      this.dialogAuthenEdit = true;
    },
    choiseAc(item) {
      this.AcType = "";
      this.dataChild = {};
      this.dialogAuthenEdit = false;
      if (item.type === "ReturnState") {
        this.ReturnState(item.item);
      }
    },
    ReturnState(item) {
      window.Swal.fire({
        title: "ยืนยัน",
        text: "ต้องการคืนสภาพสมาชิกรายนี้ใช่หรือไม่?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ใช่ ,ต้องการคืนสภาพ",
        cancelButtonText: "ไม่ ,ยกเลิก ",
        reverseButtons: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.$.post(
            this.$store.state.pathAPI + "member_eject/Del_Return_Member",
            {
              mem_id: item.mem_id,
            },
            (param) => {
              this.$store.dispatch("getlistMemberLose");
              window.Swal.fire({
                title: param.title,
                html: param.html,
                icon: param.icon,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
              });
            },
            "json"
          );
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("getlistMemberLose");
  },
};
</script>

<style></style>
