<template>
  <div class="text-center">
    <v-dialog v-model="dialogAuthenEdit" persistent width="500"
      ><form @submit.prevent="submitAuthen">
        <v-card>
          <v-card-title class="font-main grey lighten-2">
            ยืนยันสิทธิ์การแก้ไข
          </v-card-title>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  type="password"
                  v-model="ad_pass"
                  label="รหัสผ่านผู้อนุมัติ"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" type="submit"> ยืนยันสิทธิ์การแก้ไข </v-btn>
            <v-btn @click="dialogAuthenEdit = false"> ปิดหน้านี้ </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ad_pass: "",
    };
  },
  props: {
    dialogAuthenEdit: { type: Boolean, default: false },
    dataChild: {},
    AcType: { type: String, default: "" },
  },
  methods: {
    submitAuthen() {
      let vData = {};
      Object.assign(vData, { ad_pass: this.ad_pass });
      window.$.post(
        this.$store.state.pathAPI + "member/AuthenEdit",
        { ad_pass: this.ad_pass },
        (param) => {
          if (param.length > 0) {
            this.$emit("ResultAuthen", {
              item: this.dataChild,
              type: this.AcType,
            });
          } else {
            window.Swal.fire("ผิดพลาด", "กรอกรหัสสิทธิ์ผิดพลาด", "warning");
          }
          this.ad_pass = "";
        },
        "json"
      );
    },
  },
};
</script>